import React, { useState, useEffect, useContext, createContext } from "react"

// import getFirebase, { firebase, auth, db, storage, functions } from "./"
import getFirebase from "./"
// import getFirebaseArsenal from "./firebaseArsenal"

export default function useFirebase() {
  const [instance, setInstance] = useState(null)

  // useEffect(() => {
  //   setInstance(getFirebase())
  // }, [instance])
  useEffect(() => {
    setInstance(getFirebase())
  }, [])

  return instance
}

const defaultState = {
  user: {
    userId: "",
    email: "",
    name: "",
  },
}

const authContext = createContext(defaultState)
const firebaseArsenalContext = createContext()

export const useAuth = () => {
  return useContext(authContext) || defaultState
}
export const useFirebaseArsenal = () => {
  return useContext(firebaseArsenalContext) || { firebase: {} }
}

export function AuthProvider({ children }) {
  const auth = useAuthProvider()
  // const firebase = useFirebase()
  // console.log("----firebase", firebase)
  // const [userProfile, setUserProfile] = useState(null)

  // useEffect(async () => {
  //   if (auth?.user?.userId && getFirebase.db) {
  //     console.log("getFirebase", getFirebase)
  //     const userProfileRef = getFirebase.db
  //       .collection("users")
  //       .doc(auth.user.userId)

  //     const userProfileDoc = await userProfileRef.get()
  //     const userProfileData = await userProfileDoc.data()
  //     setUserProfile(userProfileData)
  //     console.log("userProfileData", userProfileData)
  //     return () => {
  //       console.log("This will be logged on unmount")
  //     }
  //   } else {
  //     setUserProfile(null)
  //     return () => {
  //       console.log("This will be logged on unmount")
  //     }
  //   }
  // }, [auth.user])
  // useEffect(() => {
  //   ;(async () => {
  //     console.log("getFirebase", firebase.db)
  //     if (auth?.user?.userId && getFirebase.db) {
  //       console.log("getFirebase", getFirebase)
  //       const userProfileRef = getFirebase.db
  //         .collection("users")
  //         .doc(auth.user.userId)

  //       const userProfileDoc = await userProfileRef.get()
  //       const userProfileData = await userProfileDoc.data()
  //       setUserProfile(userProfileData)
  //       console.log("userProfileData", userProfileData)
  //     } else {
  //       console.log("userProfileData-")
  //       setUserProfile(null)
  //     }
  //   })()
  // if (auth?.user?.userId && getFirebase.db) {
  //   console.log("getFirebase", getFirebase)
  // const userProfileRef = getFirebase.db
  //   .collection("users")
  //   .doc(auth.user.userId)
  // const userProfileDoc = await userProfileRef.get()
  // const userProfileData = await userProfileDoc.data()
  // setUserProfile(userProfileData)
  // console.log("userProfileData", userProfileData)
  // return () => {
  //   console.log("This will be logged on unmount")
  // }
  // } else {
  //   setUserProfile(null)
  // return () => {
  //   console.log("This will be logged on unmount")
  // }
  // }
  // return () => {
  //   console.log("This will be logged on unmount")
  // }
  // }, [auth.user])

  // return (
  //   <authContext.Provider value={{ ...auth, userProfile }}>
  //     {children}
  //   </authContext.Provider>
  // )

  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export function FirebaseArsenalProvider({ children }) {
  const firebaseArsenal = useFirebaseArsenalProvider() // const firebaseArsenal = useProvideFirebaseArsenal();
  // console.log("firebaseArsenal", firebaseArsenal)
  return (
    <firebaseArsenalContext.Provider value={firebaseArsenal}>
      {children}
    </firebaseArsenalContext.Provider>
  )
}

function useFirebaseArsenalProvider() {
  // const [firebaseArsenal, setFirebaseArsenal] = useState(null)
  const firebase = useFirebase()
  // console.log("----firebase", firebase)

  // useEffect(() => {
  //   if (
  //     firebaseCore?.firebase &&
  //     firebaseCore?.auth &&
  //     firebaseCore?.db &&
  //     firebaseCore?.storage &&
  //     firebaseCore?.functions
  //   ) {
  //     console.log("firebaseCore", firebaseCore)
  //     const { auth, db, storage, functions, firebase } = firebaseCore
  //     const firebaseInstance = getFirebaseArsenal(
  //       firebaseCore,
  //       auth,
  //       db,
  //       storage,
  //       functions
  //     )
  //     setFirebaseArsenal(firebaseInstance)
  //   }
  // }, [firebaseCore])

  // console.log("firebase | useFirebase", firebaseCore)
  // console.log("firebase | [auth, db, storage, functions]", [
  //   auth,
  //   db,
  //   storage,
  //   functions,
  // ])
  // console.log("firebaseInstance | getFirebaseArsenal", firebaseInstance)

  // useEffect(() => {
  // setFirebaseArsenal(firebaseInstance)
  // setFirebaseArsenal(firebase)
  // }, [])

  return {
    // firebase: firebaseArsenal,
    firebase,
  }
}

function useAuthProvider() {
  const firebase = useFirebase()
  // const { auth, db, storage, functions } = firebase;
  // console.log("firebase | useFirebase", firebase)
  // console.log("firebase | [auth, db, storage, functions]", [auth, db, storage, functions])
  // console.log("firebase | useAuthProvider", firebase)
  const defaultState = {
    userId: "",
    email: "",
    name: "",
  }
  const [user, setUser] = useState(defaultState)
  const [loading, setLoading] = useState(true)

  const handleUser = async rawUser => {
    // console.log("raw", user)
    if (rawUser) {
      const user = await formatUser(rawUser)

      setUser(user)
      setLoading(false)
      return user
    } else {
      setUser(false)
      setLoading(false)

      return false
    }
  }

  const signinWithEmail = ({ email, password }) => {
    setLoading(true)
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(response => {
        // console.log("response.user", response.user)
        handleUser(response.user)
        setLoading(false)
        // setUser(response.user);
        // return response.user;
      })
      .catch(error => {
        const errorCode = error.code
        const errorMessage = error.message
        setLoading(false)
        // console.log("errorCode", errorCode)
        // console.log("errorMessage", errorMessage)
      })
  }

  const signout = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        handleUser(false)
      })
  }

  const sendPasswordResetEmail = email => {
    // console.log('firebase.auth',firebase.auth)
    // console.log('firebase.auth(',firebase.auth())
    return firebase.auth().sendPasswordResetEmail(email).then(() => {
      return true
    })
  }

  // If we decide to have custom password reset page, confirmPasswordReset will be used.
  // https://stackoverflow.com/questions/37932983/firebase-customize-reset-password-landing-page
  const confirmPasswordReset = (password, code) => {
    const resetCode = code

    return firebase.auth().confirmPasswordReset(resetCode, password).then(() => {
      return true
    })
  }

  const sendEmailVerification = async () => {
    return firebase
      .auth()
      .currentUser.sendEmailVerification()
      .then((res) => {
        console.log('verification sent successfully', res);
      })
      .catch((err) => {
        console.log('unable to send verification', err);
      });
  };

  
  // https://firebase.google.com/docs/auth/web/email-link-auth?authuser=0#web-v8
  const trySignIn = async (email) => {
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      // Ensure HTTPS
      // url: 'https://www.example.com/finishSignUp?cartId=1234',
      url: 'http://localhost:8000/newWall/',
      handleCodeInApp: true
    };

    firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then((res) => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email);
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log('sendSignInLinkToEmail | ', {[errorCode]: errorMessage});
      });
    }


  const tryAutoSignIn = () => {
    // Get the saved email
    const saved_email = window?.localStorage?.getItem("emailForSignIn");

    // Verify the user went through an email link and the saved email is not null
    if (window?.location?.href && firebase.auth().isSignInWithEmailLink(window.location.href) && !!saved_email) {
    // if (firebase.auth().isSignInWithEmailLink(window.location.href) && !!saved_email) {
      // Sign the user in
      firebase.auth().signInWithEmailLink(saved_email, window.location.href)
      .then((result) => {
        console.log('result', result)
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn');
      })
      .catch((error) => {
        console.log('erorr',error)
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
      });
    }
  }

  // const emailLinkComplete = () => {
  //   // [START email_link_complete]
  //   // Confirm the link is a sign-in with email link.
  //   if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
  //     // Additional state parameters can also be passed via URL.
  //     // This can be used to continue the user's intended action before triggering
  //     // the sign-in operation.
  //     // Get the email if available. This should be available if the user completes
  //     // the flow on the same device where they started it.
  //     var email = window.localStorage.getItem('emailForSignIn');
  //     if (!email) {
  //       // User opened the link on a different device. To prevent session fixation
  //       // attacks, ask the user to provide the associated email again. For example:
  //       email = window.prompt('Please provide your email for confirmation');
  //     }
  //     // The client SDK will parse the code from the link for you.
  //     firebase.auth().signInWithEmailLink(email, window.location.href)
  //       .then((result) => {
  //         // Clear email from storage.
  //         window.localStorage.removeItem('emailForSignIn');
  //         // You can access the new user via result.user
  //         // Additional user info profile not available via:
  //         // result.additionalUserInfo.profile == null
  //         // You can check if the user is new or existing:
  //         // result.additionalUserInfo.isNewUser
  //       })
  //       .catch((error) => {
  //         // Some error occurred, you can inspect the code: error.code
  //         // Common errors could be invalid email and invalid or expired OTPs.
  //       });
  //   }
  //   // [END email_link_complete]
  // }

  // useEffect(() => {
  //   const unsubscribe = auth.onAuthStateChanged(handleUser)
  //   return () => unsubscribe()
  // }, [])
  useEffect(() => {
    if (!firebase) return
    // const unsubscribe = auth.onAuthStateChanged(handleUser)
    // console.log("firebase.authe", firebase.auth().onAuthStateChanged)
    const unsubscribe = firebase.auth().onAuthStateChanged(handleUser)
    return () => unsubscribe()
  }, [firebase])

  return {
    user,
    loading,
    // user: user?.providerData[0],
    signinWithEmail,
    // signinWithGoogle,
    // signup,
    signout,
    // sendSignInLinkToEmail,
    sendPasswordResetEmail,
    confirmPasswordReset,
    trySignIn,
    tryAutoSignIn,
    sendEmailVerification
  }
}

const formatUser = async user => {
  // console.log('user', user);
  return {
    userId: (user && user.uid) || "",
    email: user.email || "",
    name: user.name || "",
    // username: user.displayName || '',
    // guild: user.guild,
    // token: user.xa,
    // provider: user.providerData[0].providerId,
    // photoUrl: user.photoURL,
    // stripeRole: await getStripeRole()
  }
}
