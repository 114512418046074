import React, { useState, useEffect } from "react"
import Client from "shopify-buy"
import ReactPixel from "react-facebook-pixel"

import Context from "../context/StoreContext"

const client = Client.buildClient({
  storefrontAccessToken: process.env.SHOPIFY_ACCESS_TOKEN,
  domain: `${process.env.SHOP_NAME}.myshopify.com`,
  // storefrontAccessToken: '86575c1bba6cf83cebfe5b0dc9da9f17',
  // domain: `catbearalpha.myshopify.com`
})

// console.log('process.env.SHOP_NAME', process.env.SHOP_NAME);

const ContextProvider = ({ children }) => {
  let currentVar = {}
  let consent = false

  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    currentVar = JSON.parse(localStorage.getItem("currentVariant"))
    consent = localStorage.getItem("consent")
    // console.log('consenta dasd a sda s', consent);
    currentVar = {}
    // consent = false;
  }

  let initialStoreState = {
    client,
    consent,
    adding: false,
    removing: false,
    checkout: {
      lineItems: [],
    },
    products: [],
    shop: {},
    // currentVariant: JSON.parse(localStorage.getItem('currentVariant')) || {},
    // nextVariant: JSON.parse(localStorage.getItem('nextVariant')) || {}
    currentVariant: currentVar,
    // nextVariant: JSON.parse(localStorage.getItem('nextVariant')) || {}
    // currentHandle: ''
    // currentHandle: JSON.parse(localStorage.getItem('currentHandle')) || {}
    // currentProduct: JSON.parse(localStorage.getItem('currentProduct')) || {}
  }

  const [store, updateStore] = useState(initialStoreState)
  let isRemoved = false

  useEffect(() => {
    const initializeCheckout = async () => {
      // Check for an existing cart.
      const isBrowser = typeof window !== "undefined"
      const existingCheckoutID = isBrowser
        ? localStorage.getItem("shopify_checkout_id")
        : null

      const setCheckoutInState = checkout => {
        if (isBrowser) {
          localStorage.setItem("shopify_checkout_id", checkout.id)
        }

        updateStore(prevState => {
          return {
            ...prevState,
            checkout,
          }
        })
      }

      const createNewCheckout = () => store.client.checkout.create()
      const fetchCheckout = id => store.client.checkout.fetch(id)

      if (existingCheckoutID) {
        try {
          const checkout = await fetchCheckout(existingCheckoutID)
          // Make sure this cart hasn’t already been purchased.
          if (!isRemoved && !checkout.completedAt) {
            setCheckoutInState(checkout)
            return
          }
        } catch (e) {
          localStorage.setItem("shopify_checkout_id", null)
        }
      }

      const newCheckout = await createNewCheckout()
      if (!isRemoved) {
        setCheckoutInState(newCheckout)
      }
    }

    initializeCheckout()
  }, [store.client.checkout])

  useEffect(
    () => () => {
      isRemoved = true
    },
    []
  )

  return (
    <Context.Provider
      value={{
        store,
        addVariantToCart: (variantId, quantity) => {
          if (variantId === "" || !quantity) {
            console.error("Both a size and quantity are required.")
            return
          }

          updateStore(prevState => {
            return {
              ...prevState,
              adding: true,
            }
          })

          const { checkout, client } = store

          const checkoutId = checkout.id
          const lineItemsToUpdate = [
            {
              variantId,
              quantity: parseInt(quantity, 10),
            },
          ]

          return client.checkout
            .addLineItems(checkoutId, lineItemsToUpdate)
            .then(checkout => {
              updateStore(prevState => {
                return {
                  ...prevState,
                  checkout,
                  adding: false,
                }
              })
            })
            .catch(err => console.warn("error in addVariantToCart", err))
        },
        removeLineItem: (client, checkoutID, lineItemID) => {
          // console.log('client.checkout.removeLineItems', client.checkout.removeLineItems);
          return client.checkout
            .removeLineItems(checkoutID, [lineItemID])
            .then(res => {
              updateStore(prevState => {
                return {
                  ...prevState,
                  checkout: res,
                }
              })
            })
            .catch(err => console.warn("error in removeLineItem", err))
        },
        updateLineItem: (client, checkoutID, lineItemID, quantity) => {
          // console.log('lineItemID', lineItemID)
          // console.log('client.checkout.updateLineItems', client.checkout.updateLineItems)

          const lineItemsToUpdate = [
            {
              id: lineItemID,
              quantity: parseInt(quantity, 10),
            },
          ]
          // console.log('client.checkout', client.checkout)

          return client.checkout
            .updateLineItems(checkoutID, lineItemsToUpdate)
            .then(res => {
              updateStore(prevState => {
                console.log("new checkout", {
                  ...prevState,
                  checkout: res,
                })
                return {
                  ...prevState,
                  checkout: res,
                }
              })
            })
            .catch(err => console.warn("error in updateLineItem", err))
        },
        updateVariant: variant => {
          // console.log('current variant', variant);
          const isBrowser = typeof window !== "undefined"

          if (isBrowser) {
            localStorage.setItem("currentVariant", JSON.stringify(variant))
            // localStorage.setItem('currentHandle', JSON.stringify(handle));
            // localStorage.setItem('currentProduct', JSON.stringify(product));
          }

          updateStore(prevState => {
            return {
              ...prevState,
              currentVariant: variant,
              // currentHandle: handle
              // currentProduct: product
            }
          })
        },
        initHandleAndProduct: (handle, product) => {
          // console.log('current variant', variant);
          const isBrowser = typeof window !== "undefined"

          if (isBrowser) {
            localStorage.setItem("currentHandle", JSON.stringify(handle))
            localStorage.setItem("currentProduct", JSON.stringify(product))
          }

          updateStore(prevState => {
            return {
              ...prevState,
              currentHandle: handle,
              currentProduct: product,
            }
          })
        },
        updateVariantLegacy: variant => {
          // console.log('current variant', variant);
          const isBrowser = typeof window !== "undefined"

          if (isBrowser) {
            localStorage.setItem("currentVariant", JSON.stringify(variant))
          }

          updateStore(prevState => {
            return {
              ...prevState,
              currentVariant: variant,
            }
          })
        },
        initConsent: () => {
          const options = {
            autoConfig: true, // set pixel's autoConfig
            debug: false, // enable logs
          }

          if (store.consent === null || !store.consent) {
            ReactPixel.init("285078685916378", {}, options)
            ReactPixel.revokeConsent()
          }
        },
        activateConsent: () => {
          const isBrowser = typeof window !== "undefined"

          if (isBrowser) {
            localStorage.setItem("consent", true)
          }
          const options = {
            autoConfig: true, // set pixel's autoConfig
            debug: false, // enable logs
          }

          ReactPixel.init("285078685916378", {}, options)
          // ReactPixel.pageView();

          updateStore(prevState => {
            return {
              ...prevState,
              consent: true,
            }
          })
        },
        setConsent: c => {
          // console.log('current variant', variant);
          const isBrowser = typeof window !== "undefined"
          // console.log('store.consent', store.consent);
          if (!store.consent) {
            if (isBrowser) {
              localStorage.setItem("consent", false)
            }
            const options = {
              autoConfig: true, // set pixel's autoConfig
              debug: false, // enable logs
            }
            ReactPixel.init("285078685916378", {}, options)
            // console.log('ReactPixel', ReactPixel);
            ReactPixel.revokeConsent()
          }

          if (c) {
            if (isBrowser) {
              localStorage.setItem("consent", true)
            }
            ReactPixel.grantConsent()
            updateStore(prevState => {
              return {
                ...prevState,
                consent: true,
              }
            })
          }
        },
        updateNextVariant: variant => {
          // console.log('next variant | ', variant);
          const isBrowser = typeof window !== "undefined"

          if (isBrowser) {
            localStorage.setItem("currentVariant", JSON.stringify(variant))
          }

          updateStore(prevState => {
            return {
              ...prevState,
              nextVariant: variant,
            }
          })
        },
      }}
    >
      {children}{" "}
    </Context.Provider>
  )
}
export default ContextProvider
