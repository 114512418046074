// import "firebase/auth"
// import "firebase/firestore"
// import "firebase/functions"
import React from "react"
import ContextProvider from "./src/provider/ContextProvider"
import {
  FirebaseArsenalProvider,
  AuthProvider,
} from "./src/firebase/firebaseCentral"
// import ReactPixel from "react-facebook-pixel";
// const React = require("react");
// const ReactPixel = require("react-facebook-pixel");
// const ContextProvider = require("./src/provider/ContextProvider");
// require('typeface-quattrocento-sans')
// require('typeface-poppins');
require("typeface-cabin") // 400,500,600,700
require("typeface-alice")

// console.log('ReactPixel', ReactPixel);

// const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
// const options = {
// 	autoConfig: true, // set pixel's autoConfig
// 	debug: true // enable logs
// };
// ReactPixel.init('285078685916378', {}, options);
// console.log('ReactPixel', ReactPixel);
// Logs when the client route changes
export const onClientEntry = () => {
  // ReactPixel.init('285078685916378', {}, options);
  // ReactPixel.revokeConsent();
}
export const onRouteUpdate = ({ location, prevLocation }) => {
  // console.log('new pathname', location.pathname);
  // console.log('old pathname', prevLocation ? prevLocation.pathname : null);
  // ReactPixel.pageView();
}

export const wrapRootElement = ({ element }) => {
  return (
    <AuthProvider>
      <ContextProvider>
        <FirebaseArsenalProvider>{element}</FirebaseArsenalProvider>
      </ContextProvider>
    </AuthProvider>
  )
}

// * * GLOBAL CONTEXT INTEGRATION * *
// import React from 'react';

// import GlobalContextProvider from './src/context/GlobalContext';

// export const wrapRootElement = ({ element }) => {
//   return <GlobalContextProvider>{element}</GlobalContextProvider>
// };
