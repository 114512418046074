import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import "firebase/functions"

// https://invertase.io/blog/firebase-with-gatsby
// Firebase web config
const config = {
  apiKey: process.env.GATSBY_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_PUBLIC_FIREBASE_AUTH_DOMAIN,
  // databaseURL: process.env.GATSBY_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_PUBLIC_FIREBASE_MEASUREMENT_ID,
}

let instance = null
let auth, db, storage, functions

// export default function getFirebase() {
//   if (typeof window !== "undefined") {
//     if (instance) return instance
//     instance = firebase.initializeApp(config)

//     auth = firebase.auth()
//     db = firebase.firestore()
//     functions = firebase.functions()
//     storage = firebase.storage()

//     // return instance
//     return { firebase: instance, auth, db, functions, storage }
//   }

//   return null
// }

export default function getFirebase() {
  if (typeof window !== "undefined") {
    if (instance) {
      // console.log("origin | instance", instance)
      return instance
    }
    instance = firebase.initializeApp(config)
    // console.log("instance", instance)

    auth = firebase.auth()
    db = firebase.firestore()
    functions = firebase.functions()
    storage = firebase.storage()

    // return instance
    return { firebase: instance, auth, db, functions, storage }
  }

  return null
}

// auth = firebase.auth()
// db = firebase.firestore()
// functions = firebase.functions()
// storage = firebase.storage()

// if (typeof window !== "undefined") {
//   if (!firebase.apps.length) {
//     firebase.initializeApp(config)
//   }

//   auth = firebase.auth()
//   db = firebase.firestore()
//   functions = firebase.functions()
//   storage = firebase.storage()
// }

// const timestamp = firebase.firestore.FieldValue.serverTimestamp();

// export { firebase, auth, db, storage, functions }

// if (!firebase.apps.length) {
//   firebase.initializeApp(config)
// }

// const auth = firebase.auth()
// const db = firebase.firestore()
// const storage = firebase.storage()

// console.log("firebase/firebase", firebase)
// console.log("firebase/auth", auth)
// console.log("firebase/db", db)
// console.log("firebase/storage", storage)

// export { firebase, auth, db, storage }

// let auth, db, storage, functions, timestamp;
// export default function getFirebase() {
//   if (typeof window !== "undefined") {
//     if (instance) return instance;
//     instance = firebase.initializeApp(config);

//     const db = firebase.firestore();
//     const storage = firebase.storage();
//     const timestamp = firebase.firestore.FieldValue.serverTimestamp();
//     return { db, storage, timestamp };
//   }

//   return null;
// }

// let auth, db, storage, functions, timestamp;

// if (typeof window !== "undefined") {
//   if (!firebase.apps.length) {
//     firebase.initializeApp({
//       apiKey: process.env.GATSBY_PUBLIC_FIREBASE_API_KEY,
//       authDomain: process.env.GATSBY_PUBLIC_FIREBASE_AUTH_DOMAIN,
//       databaseURL: process.env.GATSBY_PUBLIC_FIREBASE_DATABASE_URL,
//       projectId: process.env.GATSBY_PUBLIC_FIREBASE_PROJECT_ID,
//       storageBucket: process.env.GATSBY_PUBLIC_FIREBASE_STORAGE_BUCKET,
//       messagingSenderId: process.env.GATSBY_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
//       appId: process.env.GATSBY_PUBLIC_FIREBASE_APP_ID,
//       measurementId: process.env.GATSBY_PUBLIC_FIREBASE_MEASUREMENT_ID,
//     });
//   }
// const auth = firebase.auth();
// const db = firebase.firestore();
// const functions = firebase.functions();
// const storage = firebase.storage();
// const timestamp = firebase.firestore.FieldValue.serverTimestamp();
//   auth = firebase.auth();
//   db = firebase.firestore();
//   functions = firebase.functions();
//   storage = firebase.storage();
//   timestamp = firebase.firestore.FieldValue.serverTimestamp();
// }

// export { firebase, auth, db, storage, functions, timestamp };
