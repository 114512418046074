// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-goodjournals-js": () => import("./../../../src/pages/goodjournals.js" /* webpackChunkName: "component---src-pages-goodjournals-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journals-js": () => import("./../../../src/pages/journals.js" /* webpackChunkName: "component---src-pages-journals-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-received-js": () => import("./../../../src/pages/received.js" /* webpackChunkName: "component---src-pages-received-js" */),
  "component---src-pages-suggestioncomplete-js": () => import("./../../../src/pages/suggestioncomplete.js" /* webpackChunkName: "component---src-pages-suggestioncomplete-js" */),
  "component---src-pages-termsofservice-js": () => import("./../../../src/pages/termsofservice.js" /* webpackChunkName: "component---src-pages-termsofservice-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-templates-product-confirm-js": () => import("./../../../src/templates/Product/confirm.js" /* webpackChunkName: "component---src-templates-product-confirm-js" */)
}

